export default {
    data(){
        return {

            statusList: [
                {
                    key: '01',
                    name: 'Listed',
                    select: false,
                },
                {
                    key: '02',
                    name: 'Unlisted',
                    select: false,
                },
                {
                    key: '03',
                    name: 'Redeemed',
                    select: false,
                },
            ],
            isOpenSortFilter: false,
            sortFilter: this.$route.query.order ? Number(this.$route.query.order) :  1,
        }
    },
    methods:{
        getSort(){
            // console.dir(this.sortFilter)

            switch(this.sortFilter){
                case 1:
                    return "최신순";
                case 2:
                    return "가격 높은 순";
                case 3:
                    return "가격 낮은 순";

            }
        },

        changeOrder(order){
            this.isOpenSortFilter = false;
            this.sortFilter = order;
            this.getWineList(1, 'pass');
        },

        resetFilter(){

            this.statusList.forEach((status) => {
                status.select = false;
            })

            this.filterList.forEach((filter) => {
                filter.list.forEach((item) => {
                    item.select = false;
                })

            })

            this.getWineList(1, 'pass');
        }

    }
}